import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';

import DataBlogCategories from '../data/blogCategories.json';
import generateImgAlt from '../helpers/generateImgAlt';

const propTypes = {
  tagName: PropTypes.string,
  className: PropTypes.string,
  headingLevel: PropTypes.number,
  image: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  btnText: PropTypes.string,
  category: PropTypes.array
};

const defaultProps = {
  tagName: 'article',
  className: '',
  headingLevel: 3,
  btnText: 'Read more'
};

function BlogItem({ tagName, headingLevel, className, image, title, excerpt, slug, btnText, category }) {
  const classes = classNames(className, 'BlogItem');
  const TagName = tagName;
  const Heading = `h${headingLevel}`;
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    if (image && image.publicURL) {
      setImgSrc(image && image.publicURL);
    }
  }, [image])

  let catFiltered = DataBlogCategories.items.find(x => x.id === category);
  catFiltered = typeof catFiltered !== 'undefined' ? catFiltered.name : null;

  return (
    <TagName className={classes}>
      <img
        className="BlogItem__thumbnail"
        src={imgSrc}
        alt={generateImgAlt(image && image.name)}
        key={imgSrc}
      />

      <div className="BlogItem__category">{catFiltered}</div>

      <Heading className="BlogItem__title">{title}</Heading>

      <p className="BlogItem__excerpt">{excerpt}</p>

      <Link className="BlogItem__link" to={slug}>
        {btnText}
      </Link>
    </TagName>
  );
}

BlogItem.propTypes = propTypes;
BlogItem.defaultProps = defaultProps;

export default BlogItem;
