import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SVG from 'react-inlinesvg';
import moment from 'moment';
import Sticky from 'react-stickynode';
import { filter, map } from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';
import readingTime from 'reading-time';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import generateImgAlt from '../helpers/generateImgAlt';
import { ajaxEvent } from '../helpers/tagManager';

import Form from '../components/Form';
import Blog from '../components/sections/Blog';
import Layout from '../components/Layout';
import WrappedField from '../components/WrappedField';
import Button from '../components/Button';
import StatementContact from '../components/StatementContact';
import NewsletterBlog from '../components/NewsletterBlog';

import BackgroundMainSrc from '../img/svg/pageWorkshop/main_bg.svg';
import IconEnvelopeSrc from '../img/svg/icons/envelope-empty.svg';
import IconTick from '../img/svg/icons/tick.svg';
import DataAuthors from '../data/authors.json';
import DataTags from '../data/tags.json';
import DataBlogCategories from '../data/blogCategories.json';
import DataNewsletter from '../data/newsletter.json';


import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share';

const formSchema = Yup.object().shape({
  EMAIL: Yup.string()
    .email('Email must be valid')
    .required('Email is required'),
  NAME: Yup.string(),
  newsletterBlogAgree: Yup.boolean().oneOf([true], 'You need to accept it to submit this form'),
});

const initialValues = {
  EMAIL: '',
  NAME: '',
  newsletterBlogAgree: false,
};

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.card = React.createRef();

    this.state = {
      cardOffsetTop: 0,
      hasSubmitSucceeded: false,
      hasSubmitFailed: false,
      newsletterId: ''
    };

    this.resizeEvent = this.calculateOffsetTop.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.calculateOffsetTop();
    }, 0);

    window.addEventListener('resize', this.resizeEvent);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeEvent);
  }

  calculateOffsetTop() {
    const offsetTop = this.card.current.getBoundingClientRect().top;

    this.setState({ cardOffsetTop: offsetTop });
  }

  getNewsletterData = () => {
    let { newsletter } = this.props.blog.frontmatter;
    let nFirst = newsletter !== null && newsletter.length && newsletter[0]?.value;
    let nFiltered = DataNewsletter.items.find(x => x.id === nFirst);

    return nFiltered;
  }

  sendForm = (values) => {
    const { NAME, EMAIL } = values;
    const dataNs = this.props.blog.frontmatter?.newsletterData;
    const idList = dataNs?.tokenList ? dataNs.tokenList : 'ioTGy';
    const newsletter = this.getNewsletterData().value;
    const formAPI = '/.netlify/functions/getresponse';

    // reset states
    this.setState({
      hasSubmitSucceeded: false,
      hasSubmitFailed: false
    });

    axios({
      method: 'get',
      url: formAPI,
      params: {
        function: 'whitepaper',
        idList: idList,
        email: EMAIL,
        newsletter: newsletter,
        name: NAME ? NAME : ''
      }
    })
      .then(res => {
        const newStatus = res.status === 200 || res.status === 202 || res.status === 204 ? true : false;
        const newState = newStatus ? { hasSubmitSucceeded: true } : { hasSubmitFailed: true };

        this.setState({
          ...newState
        });

        if (newStatus && dataNs?.gaEventName) {
          console.log('GA ajaxEvent: ' + dataNs.gaEventName);
          ajaxEvent(dataNs.gaEventName);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const { blogs, blog } = this.props;
    const { cardOffsetTop, hasSubmitSucceeded } = this.state;

    const {
      image,
      title,
      author,
      date,
      tags,
      category,
      button,
      newsletterGlobal,
    } = this.props.blog.frontmatter;

    let authorsExist =
      DataAuthors.items && DataAuthors.items.length && author && author.length;
    let authorFiltered =
      authorsExist && DataAuthors.items.filter(o => author.find(o2 => o.id === o2?.value));

    let catFiltered = DataBlogCategories.items.find(x => x.id === category);
    catFiltered = typeof catFiltered !== 'undefined' ? catFiltered.name : null;

    const tagsFiltered =
      DataTags.items && DataTags.items.length && tags && tags.length
        ? DataTags.items.filter(o => tags.find(o2 => o.id === o2?.value))
        : null;

    const newsletterData = this.getNewsletterData();
    const newsletterDataFieldName = this.props.blog.frontmatter?.newsletterData?.fieldName;

    const btnIsDisabled = hasSubmitSucceeded ? { 'disabled': 'disabled' } : '';

    const dateFormatted = !isNaN(new Date(date))
      ? moment(date).format('D MMMM YYYY')
      : null;

    const shareUrl = typeof window !== 'undefined' ? window.location.href : '';

    const time = readingTime(blog.html);

    return (
      <>
        <div className="BlogPost">
          <div className="BlogPost__background">
            <SVG
              src={BackgroundMainSrc}
              uniquifyIDs={false}
              className="BlogPost__background-image"
            />
          </div>
          <div className="BlogPost__container">
            <div className="BlogPost__container__col">
              <div className="BlogPost__top">
                {catFiltered && (
                  <div className="BlogPost__top__col">
                    <div className="BlogPost__category">{catFiltered}</div>
                  </div>
                )}
                <div className="BlogPost__top__col">
                  <div className="BlogPost__time">{time.text}</div>
                </div>
              </div>
              <h1>{title}</h1>
              <div
                className="BlogPost__content"
                dangerouslySetInnerHTML={{ __html: blog.html }}
              />

              {button && button.buttonUrl && (
                <div className="BlogPost__button">
                  <Button
                    href={button.buttonUrl}
                    target="_blank"
                  >
                    {button.buttonLabel ? button.buttonLabel : 'Read More'}
                  </Button>
                </div>
              )}

              {newsletterGlobal?.visible && (
                <NewsletterBlog
                  title={newsletterGlobal?.title}
                  subtitle={newsletterGlobal?.subtitle}
                />
              )}

              {newsletterData && (
                <div className="BlogPost__newsletter">
                  <div className="BlogPost__newsletter__col">
                    <img
                      className="BlogPost__newsletter__image"
                      src={newsletterData.image}
                      alt={generateImgAlt(image.name)}
                    />
                  </div>
                  <div className="BlogPost__newsletter__col">
                    <h3 className="BlogPost__newsletter__title">
                      <ReactMarkdown
                        children={newsletterData.title}
                        rehypePlugins={[rehypeRaw]}
                      />
                    </h3>
                    <p className="BlogPost__newsletter__descr">
                      {newsletterData.text}
                    </p>
                    <br />
                    <Formik
                      validationSchema={formSchema}
                      enableReinitialize={true}
                      initialValues={initialValues}
                      onSubmit={this.sendForm}
                    >
                      {({ errors, touched }) => (
                        <>
                          <Form>

                            {!hasSubmitSucceeded ? (
                              <>
                                <input type="hidden" value={newsletterData.value} name="NEWSLETTER" />

                                {newsletterDataFieldName &&
                                  <div className="Form__level  Form__level--smallMargin">
                                    <WrappedField
                                      type="name"
                                      name="NAME"
                                      error={errors.NAME}
                                      isTouched={touched.NAME}
                                      placeholder="Type your name"
                                    />
                                  </div>
                                }

                                <div className="Form__level  Form__level--smallMargin">
                                  <WrappedField
                                    type="email"
                                    name="EMAIL"
                                    error={errors.EMAIL}
                                    isTouched={touched.EMAIL}
                                    placeholder="Type your email"
                                    iconSrc={IconEnvelopeSrc}
                                  />
                                </div>

                                <WrappedField
                                  name="newsletterBlogAgree"
                                  label="I agree to receive via email marketing information (e.g. about news, products and services, events or training courses)"
                                  error={errors.newsletterBlogAgree}
                                  isTouched={touched.newsletterBlogAgree}
                                  type="checkbox"
                                  isCheckbox={true}
                                />

                                <Button
                                  type="submit"
                                  className="BlogPost__newsletter__submit"
                                  variants={['full']}
                                  {...btnIsDisabled}
                                >
                                  Download
                                </Button>

                                <div className="BlogPost__newsletter__statement">
                                  <StatementContact />
                                </div>
                              </>
                            ) : (
                              <div className="BlogPost__newsletter__success">
                                <div className="BlogPost__newsletter__success__col">
                                  <SVG
                                    src={IconTick}
                                    className="BlogPost__newsletter__success__icon"
                                  />
                                </div>
                                <div className="BlogPost__newsletter__success__col">
                                  <div className="BlogPost__newsletter__success__title">
                                    Thank you!
                                  </div>
                                  <div className="BlogPost__newsletter__success__subtitle">
                                    An email is on the way, please check your inbox
                                  </div>
                                </div>
                              </div>
                            )}
                          </Form>
                        </>
                      )}
                    </Formik>
                  </div>
                </div>
              )}

              <div className="BlogPost__tags">
                {tagsFiltered != null &&
                  tagsFiltered.map((e, i) => (
                    <div key={i} className="BlogPost__tags__item">
                      {e.name}
                    </div>
                  ))}
              </div>
              <div className="BlogPost__information">
                <div className="BlogPost__information__col">
                  {dateFormatted && (
                    <div className="BlogPost__date">
                      <label>Last updated: </label> {dateFormatted}
                    </div>
                  )}
                </div>
                <div className="BlogPost__information__col">
                  <FacebookShareButton
                    url={shareUrl}
                    className="BlogPost__information__btnSocial"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>

                  <TwitterShareButton
                    url={shareUrl}
                    className="BlogPost__information__btnSocial"
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>

                  <LinkedinShareButton
                    url={shareUrl}
                    className="BlogPost__information__btnSocial"
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </div>
              </div>
              {!!authorFiltered?.length && (
                <div className="BlogPost__footer">
                  <div className="BlogPost__footer__label">Written by</div>
                  <div className="BlogPost__footer__content">
                    {authorFiltered != null && authorFiltered.map((e, i) => (
                      <div className="BlogPost__user" key={i}>
                        {e.avatar && (
                          <div className="BlogPost__user__col">
                            <div
                              className="BlogPost__user__avatar"
                              style={{
                                backgroundImage: `url(${e.avatar})`,
                              }}
                            />
                          </div>
                        )}
                        <div className="BlogPost__user__col">
                          <div className="BlogPost__user__name">
                            {e.name}
                          </div>
                          <div className="BlogPost__user__title">
                            {e.title}
                          </div>
                          {e.linkedin && (
                            <a href={e.linkedin} className="BlogPost__user__linkSocial" target="_blank" rel="noopener noreferrer">
                              <LinkedinIcon size={18} round />
                            </a>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="BlogPost__container__col">
              <Sticky
                className="BlogPost__sticky"
                top={cardOffsetTop}
                enabled={true}
                bottomBoundary=".BlogPost__content"
              >
                <div className="BlogPost__sticky__inner" ref={this.card}>
                  <div className="BlogPost__social">
                    <h4 className="BlogPost__social__title">
                      Like this post?
                      <br />
                      <b>Spread the word</b>
                    </h4>
                    <FacebookShareButton
                      url={shareUrl}
                      className="BlogPost__social__btn"
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={shareUrl}
                      className="BlogPost__social__btn"
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <LinkedinShareButton
                      url={shareUrl}
                      className="BlogPost__social__btn"
                    >
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                  </div>
                </div>
              </Sticky>
            </div>
          </div>
        </div>
        <Blog className="BlogPost__blogs" data={blogs} />
      </>
    );
  }
}

const BlogPost = props => {
  const { frontmatter } = props.data.blog;
  const { blogs, blog } = props.data;
  const related_posts = blog.frontmatter.related_posts ? blog.frontmatter.related_posts : [];
  const idBlogCurrent = blog.frontmatter.id;
  const postsNumber = 6;

  const dataTransformed = map(blogs.edges, (item) => {
    let slugFrontmatter = item.node.frontmatter.slug;
    let slug = slugFrontmatter ? slugFrontmatter : item.node.fields.slug;
    return {
      ...item.node.frontmatter,
      ...{ id: item.node.frontmatter.id },
      ...{ excerpt: item.node.excerpt },
      slug: slug // should be last to override value
    }
  }).filter((e) => e.id !== idBlogCurrent);

  const postsIdRelated = related_posts.map((item) => item.id);

  const dataRelated = filter(dataTransformed, (e) => postsIdRelated.includes(e.id));
  const dataNotRelated = filter(dataTransformed, (e) => !postsIdRelated.includes(e.id));
  const dataRandom = [...dataNotRelated].sort(() => 0.5 - Math.random()).slice(0, postsNumber - dataRelated.length);
  const dataFinal = [...dataRelated, ...dataRandom];

  const mergedProps = {
    ...props.data,
    ...{ blogs: dataFinal },
    ...{ location: props.location }
  };

  return (
    <Layout variants={['backgrounded']} meta={frontmatter.meta} dataPage={mergedProps}>
      <BlogPostTemplate {...mergedProps} />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`query BlogPostByID($id: String!) {
  blog: markdownRemark(id: {eq: $id}) {
    id
    html
    excerpt
    frontmatter {
      meta {
        title
        description
        image {
          publicURL
          name
        }
        hide_robot
      }
      id
      slug
      date
      title
      category
      description
      image {
        publicURL
        name
      }
      author {
        label
        value
      }
      tags {
        label
        value
      }
      newsletterGlobal {
        visible
        title
        subtitle
      }
      newsletter {
        label
        value
      }
      newsletterData {
        tokenList
        gaEventName
        fieldName
      }
      button {
        buttonLabel
        buttonUrl
      }
      related_posts {
        id
      }
    }
  }
  blogs: allMarkdownRemark(
    sort: {frontmatter: {order: ASC}}
    filter: {frontmatter: {templateKey: {eq: "blog-post"}, published: {ne: false}}}
  ) {
    edges {
      node {
        excerpt(pruneLength: 150)
        id
        fields {
          slug
        }
        frontmatter {
          title
          id
          slug
          image {
            publicURL
            name
          }
          category
        }
      }
    }
  }
}`;
