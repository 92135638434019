import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import xss from 'xss';
import axios from 'axios';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { ajaxSuccess } from '../helpers/tagManager';

import Form from './Form';
import WrappedField from './WrappedField';
import Button from './Button';
import StatementContact from './StatementContact';

import IconEnvelopeSrc from '../img/svg/icons/envelope-empty.svg';

const formSchema = Yup.object().shape({
  EMAIL: Yup.string()
    .email('Email must be valid')
    .required('Email is required'),
});

const initialValues = {
  EMAIL: '',
};

const initialState = {
  hasSubmitSucceeded: false,
  hasSubmitFailed: false,
  statement: ''
}

const propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

const defaultProps = {
  title: '',
  subtitle: '',
};

const idHTML = 'blog-newsletter';

class Newsletter extends React.Component {
  constructor(props) {
    super(props);

    this.fieldNames = ['EMAIL'];

    this.state = initialState;
  }

  resetStates() {
    this.setState(initialState);
  }

  sendForm = async data => {
    const email = data['EMAIL'];
    const formAPI = '/.netlify/functions/getresponse';

    this.resetStates();

    await axios({
      method: 'get',
      url: formAPI,
      params: {
        function: 'newsletter',
        idList: 'isgsP',
        email: email,
        tags: JSON.stringify(['VdaSb']) // newsletter_blog_under
      }
    })
      .then(res => {
        const newState = res.status == 202
          ? { hasSubmitSucceeded: true }
          : { hasSubmitFailed: true };

        if (res.status == 202) {
          trackCustomEvent({
            category: "newsletter",
            action: "submit"
          });

          ajaxSuccess(idHTML);

          this.setState({
            ...newState,
            ...{ statement: 'Thank you for subscribing!' }
          });
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.status == 409) {
            this.setState({
              statement: 'This email address is already subscribed'
            });
          }
        }
      });
  };

  onSubmit = values => {
    this.sendForm(values);
  };

  render() {
    const { hasSubmitSucceeded, statement } = this.state;
    const { title, subtitle } = this.props;

    const btnIsDisabled = hasSubmitSucceeded ? { 'disabled': 'disabled' } : '';

    return (
      <section className="NewsletterBlog" id="newsletter">
        {title && (
          <h2 className="NewsletterBlog__title">{title}</h2>
        )}

        {subtitle && (
          <h3 className="NewsletterBlog__subtitle">{subtitle}</h3>
        )}

        <Formik
          validationSchema={formSchema}
          onSubmit={this.onSubmit}
          initialValues={initialValues}
        >
          {({ errors, touched }) => (
            <>
              <Form className="NewsletterBlog__form" id={idHTML}>
                <div className="Form__level Form__level--smallMargin">
                  <WrappedField
                    type="email"
                    name="EMAIL"
                    error={errors.EMAIL}
                    isTouched={touched.EMAIL}
                    placeholder="Type your email"
                    iconSrc={IconEnvelopeSrc}
                    className="WrappedField--messageSmall"
                  />
                </div>
                <div className="Form__level">
                  <Button type="submit" className="NewsletterBlog__submit Form__submit" variants={['full']} {...btnIsDisabled}>
                    Join Us
                  </Button>
                </div>
              </Form>
              {statement.length >= 1 &&
                <div className="NewsletterBlog__statement">
                  <b dangerouslySetInnerHTML={{ __html: xss(statement) }} />
                </div>
              }
              <div className="NewsletterBlog__statement">
                <StatementContact />
              </div>
            </>
          )}
        </Formik>
      </section>
    );
  }
}

Newsletter.propTypes = propTypes;
Newsletter.defaultProps = defaultProps;

export default Newsletter;
