import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import blogData from '../../data/blog';
import BlogItem from '../../components/BlogItem';
import Button from '../../components/Button';

class Blog extends React.Component {

  static defaultProps = {
    data: []      
  }

  render() {
    const { className = '', data } = this.props;

    return (
      <section className={classNames('Blog', className)}>
        <div className="Blog__container">
          <h2 className="Blog__title">{blogData.title}</h2>

          <div className="Blog__items">
            {data.map(({ title, image, excerpt, slug, category }, i) => (
              <BlogItem 
                key={i}
                image={image}
                title={title}
                excerpt={excerpt}
                slug={slug}
                category={category}
                btnText='Read more'
              />
            ))}
          </div>

          <div className="Blog__cta">
            <Button to="/blog">Check All</Button>
          </div>
        </div>
      </section>
    );
  }
}

Blog.propTypes = {
  data: PropTypes.array
};

export default Blog;
